




























































































import {Component, Vue} from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import {apiChangePluginStatus, apiDeletePlugin, apiGetPluginList} from '@/api/imagetru3d'

@Component({
  components: {
    LsDialog
  }
})
export default class DevicePlugin extends Vue {
  $refs!: { plTreeTable: any }

  plugin_list = []
  plugin_loading = false

  // 获取设备插件列表
  getDevicePluginList(): void {
    apiGetPluginList({}).then((resp) => {
      if (resp.length != 0) {
        this.plugin_loading = false
        this.plugin_list = resp
      }
    })
  }

  // 设备类型状态调整
  handleStatus(value: number, id: number) {
    apiChangePluginStatus({
      id,
      soft_status: value
    }).then(() => {
      this.getDevicePluginList()
    })
  }

  goList(scope: any) {
    this.$router.push({
      path: '/setting/imagetru3d/device_plugins',
      query: {
        id: scope.row.id,
        name: scope.row.soft_name
      },
    })
  }

  // 设备类型删除
  handleDelete(id: number) {
    apiDeletePlugin({id}).then(() => {
      this.getDevicePluginList()
    })
  }

  // 创建当前组件的时候，获取设备类型列表
  created() {
    this.getDevicePluginList()
  }
}
